<template>
    <div>

    </div>
</template>

<script>
export default {
  name: 'Students',
  props: {

  }
}
</script>

<style>

</style>
